import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"

import Layout from "../components/layout"
import { Footer } from "../components/footer"
import SEO from "../components/seo"
import { maxWidth } from "../components/maxwidth"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import PhoneIcon from "@material-ui/icons/Phone"

import { GatsbyImage } from "gatsby-plugin-image"
import { colors } from "../components/colors"
import { ContactForm } from "../components/contactForm"

const useStyles = makeStyles(theme => ({
  banner: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    "& .overlay": {
      backgroundColor: "rgba(36,120,153,0.65)",
      mixBlenderMode: "multiply",
    },

    "& .bannerText": {
      marginTop: "90px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },

  phone: {
    color: colors.azul,
    "& svg": {
      width: "45px",
      height: "45px",
      color: colors.azul,
    },
  },
}))

const Contato = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner_quem_somos.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const classes = useStyles()

  const [current, setCurrent] = useState(null)

  return (
    <Layout>
      <SEO
        title="Contato"
        description="Para falar conosco é bem simples. Basta preencher o formulário
                  abaixo, que retornaremos contato o mais breve possível."
      />
      <Box
        width={1}
        height="100vh"
        display="flex"
        flexDirection="column"
        style={{ overflowY: "auto" }}
      >
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          height={{ xs: "320px" }}
          mt={{ xs: 0 }}
          className={classes.banner}
          position="relative"
        >
          <GatsbyImage
            image={data.banner.childImageSharp.gatsbyImageData}
            alt="banner home"
          />
          <Box
            width={1}
            height={1}
            position="absolute"
            className="overlay"
          ></Box>
          <Box
            width={1}
            maxWidth={maxWidth}
            position="absolute"
            display="flex"
            flexWrap="wrap"
            height="100%"
            alignItems="center"
            px={{ xs: "10px", sm: "20px" }}
          >
            <Box width={1} p={{ xs: "30px", sm: "50px" }}>
              <Typography variant="h4" className="bannerText">
                CONTATO
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box width={1} display="flex" justifyContent="center" py="50px">
          <Box
            width={1}
            maxWidth={maxWidth}
            display="flex"
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
            px={{ xs: "10px", sm: "20px" }}
            style={{ gap: "15px" }}
          >
            <Box
              width={{ xs: 1, sm: 0.5 }}
              display="flex"
              flexDirection="column"
            >
              <Box color={colors.gray}>
                <Typography variant="h4">Fale Conosco</Typography>
              </Box>
              <Box color={colors.grayText} my="30px">
                <Typography variant="body1">
                  Para falar conosco é bem simples. Basta preencher o formulário
                  abaixo, que retornaremos contato o mais breve possível.
                </Typography>
              </Box>
              <ContactForm />
              <Box color={colors.azulText} my="20px">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  SE PREFERIR, ENTRE EM CONTATO ATRAVÉS DO NÚMERO ABAIXO:
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-around"
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box display="flex" className={classes.phone}>
                  <Box>
                    <PhoneIcon />
                  </Box>
                  <Box color={colors.azulText}>
                    <Typography variant="h4">(11) 2409-5381</Typography>
                  </Box>
                </Box>

                <Box display="flex" className={classes.phone}>
                  <Box>
                    <PhoneIcon />
                  </Box>
                  <Box color={colors.azulText}>
                    <Typography variant="h4">(11) 2409-2294</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width={{ xs: 1, sm: 0.5 }} display="flex">
              <iframe
                width="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?q=Rua%20Abraham%20Lincoln%2C%20n%C2%BA%20229%2C%20Jd.%20Guarulhos%2C%20Guarulhos%20-%20SP%2C%20CEP%2007090-100&amp;t=m&amp;z=17&amp;output=embed&amp;iwloc=near"
                title="Rua Abraham Lincoln, nº 229, Jd. Guarulhos, Guarulhos - SP, CEP 07090-100"
                aria-label="Rua Abraham Lincoln, nº 229, Jd. Guarulhos, Guarulhos - SP, CEP 07090-100"
              ></iframe>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Layout>
  )
}

export default Contato
